type Langs = {
    [key: string]: Record<string, string>
}

const langs: Langs = {
    fr: {
        'alertBigPrivacy': 'Bienvenue sur Tuto.com ! Nous utilisons des cookies pour améliorer votre expérience, c\'est ok pour vous ?',
        'acceptAll': 'Tout accepter',
        'personalize': 'Personnaliser',

        'allow': 'Autoriser',
        'deny': 'Interdire',
        'noCookie': 'Ce service ne dépose aucun cookie.',
        'useCookie': 'Ce service peut déposer',
        'useCookieCurrent': 'Ce service a déposé',
        'useNoCookie': 'Ce service n\'a déposé aucun cookie.',
        'more': 'En savoir plus',
        'source': 'Voir le site officiel',
        'credit': 'Gestion des cookies par tarteaucitron.js',
        'noServices': 'Ce site n\'utilise aucun cookie nécessitant votre consentement.',

        'allowAll': 'Tout accepter',
        'denyAll': 'Tout refuser',

        'mandatoryTitle': 'Cookies obligatoires',
        'mandatoryText': 'Ce site utilise des cookies nécessaires à son bon fonctionnement. Ils ne peuvent pas être désactivés.',

        // tuto addition
        'modal_header_content': 'Chargement',
        'modal_button_confirm': 'Confirmer',
        'modal_button_cancel': 'Annuler',

        'modalAriaLabel': 'dialog window',
        'modalCheckAll': 'Tout cocher',
    },
    en: {
        // tuto addition
        'modal_header_content': 'Loading',
        'modal_button_confirm': 'Confirm',
        'modal_button_cancel': 'Cancel',
        'modalAriaLabel': 'dialog window',
        'modalCheckAll': 'Check all',
    }

}

export {
    langs
}
