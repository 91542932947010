import { TutoCitronHtmlSource } from './tutocitron-html-templates.ts'
import { langs } from './tutocitron.langs.ts'

const ajax_prefixCitron = '/a_'
type TemplateIDCitron = `#${string}-template`
const getFromTemplateCitron = <T extends Element>(TemplateIDCitron: TemplateIDCitron): T | undefined => {
    return document.querySelector<HTMLTemplateElement>(`${TemplateIDCitron}`)?.content?.firstElementChild?.cloneNode(true) as T | undefined
}

let TutoCitronInst

// tarteaucitron.userInterface.OpenPanel = function () {}
//
// tarteaucitron.userInterface.ClosePanel = function () {}

// Overload openAlert() from tarteaucitron to avoid auto set focus on element (scroll bottom gate)
tarteaucitron.userInterface.openAlert = function () {

    'use strict'
    var c = 'tarteaucitron'
    tarteaucitron.userInterface.css(c + 'Percentage', 'display', 'none')
    tarteaucitron.userInterface.css(c + 'AlertSmall', 'display', 'none')
    tarteaucitron.userInterface.css(c + 'Icon', 'display', 'none')
    tarteaucitron.userInterface.css(c + 'AlertBig', 'display', 'block')
    tarteaucitron.userInterface.css(c + 'AlertBig', 'height', '0')
    tarteaucitron.userInterface.css(c + 'AlertBig', 'overflow', 'hidden')
    tarteaucitron.userInterface.addClass(c + 'Root', 'tarteaucitronBeforeVisible')

    //ie compatibility
    var tacOpenAlertEvent
    if (typeof (Event) === 'function') {
        tacOpenAlertEvent = new Event('tac.open_alert')
    } else {
        tacOpenAlertEvent = document.createEvent('Event')
        tacOpenAlertEvent.initEvent('tac.open_alert', true, true)
    }
    //end ie compatibility

    window.dispatchEvent(tacOpenAlertEvent)

}

tarteaucitron.userInterface.jsSizing = function (type) {
    TutoCitronInst?.jsSizing(type)
}

tarteaucitron.userInterface.respondAll = function (status, type, allowSafeAnalytics) {

    'use strict'

    const tac_services = tarteaucitron.services

    let tac_service,
        key,
        index = 0,
        _reloadThePage = false

    for (index = 0; index < tarteaucitron.job.length; index += 1) {

        if (typeof type !== 'undefined'
            && type !== ''
            && tac_services[tarteaucitron.job[index]].type !== type) {
            continue
        }

        if (allowSafeAnalytics
            && typeof tac_services[tarteaucitron.job[index]].safeanalytic !== 'undefined'
            && tac_services[tarteaucitron.job[index]].safeanalytic === true) {
            continue
        }

        tac_service = tac_services[tarteaucitron.job[index]]

        key = tac_service.key

        if (tarteaucitron.state[key] !== status) {

            if (status === false
                && tarteaucitron.launch[key] === true) {
                _reloadThePage = true
            }

            if (status === true
                && tarteaucitron.launch[key] !== true) {

                tarteaucitron.pro('!' + key + '=engage')

                tarteaucitron.launch[key] = true

                if (typeof tarteaucitronMagic === 'undefined'
                    || tarteaucitronMagic.indexOf('_' + key + '_') < 0) {
                    tarteaucitron.services[key].js()
                }

                tarteaucitron.sendEvent(key + '_loaded')
            }

            const itemStatusElem = document.getElementById('tacCurrentStatus' + key)

            if (status === true) {
                itemStatusElem.innerHTML = tarteaucitron.lang.allowed
            } else {
                itemStatusElem.innerHTML = tarteaucitron.lang.disallowed
            }

            tarteaucitron.state[key] = status

            tarteaucitron.cookie.create(key, status)

            tarteaucitron.userInterface.color(key, status)

        }
    }

    pushConfirmation(_reloadThePage)
}

tarteaucitron.events.init = function () {
    tarteaucitronCustomText = langs[tarteaucitron.getLanguage()] || langs['en']
    tarteaucitronForceLanguage = document.documentElement.getAttribute('lang') || 'fr'
}

tarteaucitron.events.load = function () {
    TutoCitronInst = new TutoCitron()
}

class TutoCitron {

    /**
     * @type {HTMLElement}
     */
    #floatingElement

    /**
     * @type {HTMLElement}
     */
    #btnAcceptAll

    /**
     * @type {HTMLElement}
     */
    #btnDenyAll

    /**
     * @type {HTMLElement}
     */
    #btnCustomize

    /**
     * @type {HTMLElement}
     */
    #tutoModal

    /**
     * @type {HTMLElement}
     */
    #tacRoot

    /**
     * @type {HTMLElement}
     */
    #tacModal

    /**
     * main wrapper for check all input switch
     * @type {HTMLElement}
     */
    #switchAll

    /**
     *
     * @type {string}
     */
    #switchAllId = 'switch-check_all'

    #acceptAllCallback
    #denyAllCallback
    #customizeAllCallback

    #switchAllCallback

    /**
     *
     * @type {number}
     */
    #checkedServicesCount
    #modalInstance

    constructor() {

        this.#tacRoot = document.getElementById('tarteaucitronRoot')

        // [!] if no root found, script will stop here
        if (!this.#tacRoot) {
            console.error('no root element found !')
            return
        }

        this.#initFloatingBar()

        // [!] if no floating bar found, script will stop here
        if (!this.#floatingElement) {
            console.error('no floating element found !')
            return
        }

        // reset checked service count
        this.#checkedServicesCount = 0

        // find and store floating bar buttons
        this.#btnAcceptAll = this.#floatingElement.querySelector('.cookies-accept-all')
        this.#btnDenyAll = this.#floatingElement.querySelector('.cookies-reject-all')
        this.#btnCustomize = this.#floatingElement.querySelector('.cookies-customize')

        // prepare callbacks
        this.#acceptAllCallback = this.acceptAll.bind(this)
        this.#denyAllCallback = this.denyAll.bind(this)
        this.#customizeAllCallback = this.customize.bind(this)
        this.#switchAllCallback = this.switchAll.bind(this)

        // HTML manipulations
        this.#buildModal()
        this.#convertToFloatingBar()

        // prepare events (floating bar)
        this.#initEvents()
    }

    /**
     * Build & return a <li> matching service formatting for check all feature
     * @return {HTMLElement}
     */
    #buildCheckAllRow() {

        /**
         * @type {HTMLElement}
         * @private
         */
        let _li = document.createElement('li')
        _li.id = 'tarteaucitronServicesTitle_CheckAll'
        _li.classList.add('tarteaucitronLine', 'tarteaucitronLineCheckAll')

        /**
         * @type {HTMLElement}
         * @private
         */
        let _title = document.createElement('div')
        _title.id = 'tarteaucitronServices_CheckAll'
        _title.classList.add('tarteaucitronTitle')
        _title.innerText = TutoCitron.localize('modalCheckAll')

        _li.appendChild(_title)

        /**
         * @type {HTMLElement}
         * @private
         */
        let _ask = document.createElement('div')

        _ask.classList.add('tarteaucitronAsk')

        // all services are enabled, check all
        let _isChecked = (this.#checkedServicesCount === Object.keys(tarteaucitron.added).length)

        /**
         * @type {HTMLElement}
         * @private
         */
        this.#switchAll = this.#createSwitchElement(this.#switchAllId, 'check_all', '1', 'tarteaucitronServices_CheckAll', _isChecked, false)

        _ask.appendChild(this.#switchAll)

        _li.appendChild(_ask)

        return _li
    }

    /**
     * Create modal content
     * @return {string}
     */
    #buildModalContent() {

        /**
         * Selectors to be removed from source content
         * @type {*[]}
         * @private
         */
        let _selectorsToRemove = [
            '.clear',
            '.tarteaucitronReadmoreSeparator',
            '.tarteaucitronReadmoreInfo',
            '.tarteaucitronReadmoreOfficial',
        ]

        // div.tarteaucitronBorder => contains <ul> which contains <li> of services
        let _tacServicesWrapper = this.#tacModal.querySelector('.tarteaucitronBorder')

        // remove unwanted selectors
        for (const [idx, _selector] of _selectorsToRemove.entries()) {
            _tacServicesWrapper.querySelectorAll(_selector).forEach((elem) => elem.remove())
        }

        // store all services
        let _tacServices = _tacServicesWrapper.querySelector('ul:first-child').querySelectorAll(':scope > li')

        // loop services to convert button set to switch input
        for (const [_servIdx, _service] of _tacServices.entries()) {

            if (_service.id === 'tarteaucitronServicesTitle_mandatory') {
                _service.classList.add('tarteaucitronServicesTitle_mandatory')
            }

            if (_service.id === 'tarteaucitronServicesTitle_mandatory' || _service.style.display === 'block') {
                for (const [_btnElmIdx, _btnElm] of _service.querySelector(':scope > ul').querySelectorAll('button').entries()) {
                    this.#convertButtonToSwitch(_btnElm)
                }
            }
        }

        // [!] HAS TO BE DONE AFTER SERVICES CONVERSION TO BUTTON
        // add check all row right after mandatory cookies
        _tacServicesWrapper.querySelector('#tarteaucitronServicesTitle_mandatory').after(this.#buildCheckAllRow())

        // remove unused <br>
        _tacServicesWrapper.querySelectorAll('br').forEach((elem) => elem.remove())

        // swap <button> to <span> in .tarteaucitronTitle element
        _tacServicesWrapper.querySelectorAll('#tarteaucitronServicesTitle_mandatory > .tarteaucitronTitle > button, button.catToggleBtn').forEach((elem) => {
            let _newElem = document.createElement('span')
            _newElem.innerText = elem.innerText.trim()
            elem.parentNode.replaceChild(_newElem, elem)
        })

        return _tacServicesWrapper.outerHTML
    }

    /**
     *
     */
    #initModal() {
        this.#modalInstance = new TutoCitronModal(this.#tutoModal)
    }

    /**
     * Create tuto modal content from tac vanilla content
     */
    #buildModal() {

        const _modalHtml = this.#getHtmlSourceElement('modal')

        /**
         * add this point, if modal source is not found, no need to continue
         */
        if ('' === _modalHtml) {
            console.error('modal source code not found !')
            return
        }

        /**
         * add <dialog> source in tac root
         */
        this.#tacRoot.innerHTML += _modalHtml

        /**
         * set tuto modal from freshly added dialog
         */
        this.#tutoModal = this.#tacRoot.querySelector('#modal-tutocitron')

        // Tarteaucitron modal & contents
        this.#tacModal = document.getElementById('tarteaucitron')

        this.#tacModal.querySelector('#tarteaucitronInfo').remove()
        this.#tacModal.querySelectorAll('.tarteaucitronName')[0].remove()
        this.#tacModal.querySelectorAll('.tarteaucitronAsk')[0].remove()

        // header
        this.#tutoModal.querySelector('.heading').innerHTML = this.#tacModal.querySelector('#dialogTitle').innerText

        // content
        this.#tutoModal.querySelector('.dialog-main-content').innerHTML = this.#buildModalContent()

        // init modal => fire modal class
        this.#initModal()
    }

    /**
     *
     * @param id
     * @param name
     * @param value
     * @param labelledby
     * @param isChecked
     * @param isDisabled
     * @return {HTMLElement}
     */
    #createSwitchElement(id, name, value, labelledby, isChecked, isDisabled) {

        /**
         * @type {HTMLElement}
         * @private
         */
        let _switchWrapper = document.createElement('div')
        _switchWrapper.classList.add('gui-switch-wrapper')

        /**
         * @type {HTMLElement}
         * @private
         */
        let _switchLabel = document.createElement('label')

        _switchWrapper.appendChild(_switchLabel)

        /**
         * @type {HTMLElement}
         * @private
         */
        let _switchInner = document.createElement('div')
        _switchInner.classList.add('gui-switch')

        _switchLabel.appendChild(_switchInner)

        /**
         * @type {HTMLElement}
         * @private
         */
        let _switchInput = document.createElement('input')
        _switchInput.classList.add('checkbox-switch')
        _switchInput.type = 'checkbox'
        _switchInput.name = name
        _switchInput.value = value
        _switchInput.setAttribute('role', 'switch')

        if (isDisabled === true) {
            _switchInput.disabled = true
        }

        if (isChecked === true) {
            _switchInput.setAttribute('checked', 'checked')
        }

        if (id) {
            _switchInput.id = id
        }

        if (labelledby) {
            _switchInput.setAttribute('aria-labelledby', labelledby)
        }

        _switchInner.appendChild(_switchInput)

        return _switchWrapper
    }

    /**
     * Create & append a switch input beside button, hide button
     * @param _buttonElement
     */
    #convertButtonToSwitch(_buttonElement) {
        if ('hidden' !== _buttonElement.style.visibility && 'none' !== _buttonElement.style.display) {

            /**
             * hide button
             */
            _buttonElement.style.display = 'none'

            /**
             * only add switch once per button set
             */
            if (null === _buttonElement.parentElement.querySelector('.gui-switch-wrapper')) {

                let isChecked = false, isDisabled = false

                let _slug = ''
                if (!_buttonElement.closest('li').id) {
                    _slug = _buttonElement.closest('li').closest('ul').closest('li').id.replace('tarteaucitronServicesTitle_', '')
                } else {
                    _slug = _buttonElement.closest('li').id.replace('Line', '')
                }

                let _suffix = ''
                if ('mandatory' === _slug) {
                    isChecked = true
                    isDisabled = true
                } else {
                    _suffix = '__service' // append to find it while processing submit
                }

                /**
                 * service name used to toggle switch
                 * @private
                 */
                const _service = _buttonElement.id.replace('Allowed', '')
                if (tarteaucitron.state[_service]) {
                    isChecked = true
                    this.#checkedServicesCount++
                }

                _buttonElement.parentElement.appendChild(this.#createSwitchElement('switch-' + _slug + _suffix, 'switch_' + _slug, '1', '', isChecked, isDisabled))
            }
        }
    }

    /**
     *
     * @param name
     * @return {string|*|string}, html source of named element in TutoCitronHtmlSource
     */
    #getHtmlSourceElement(name) {
        for (const [idx, entry] of TutoCitronHtmlSource.entries()) {
            if (entry.name === name) {
                return entry.html
            }
        }
        return ''
    }

    /**
     * pick floating bar source in json and append it to root html
     */
    #initFloatingBar() {

        const _floatingBarHtml = this.#getHtmlSourceElement('floating-bar')

        if ('' === _floatingBarHtml) {
            console.error('floating bar source code not found !')
            return
        }

        this.#tacRoot.innerHTML += _floatingBarHtml

        this.#floatingElement = getFromTemplateCitron<HTMLElement>('#floating-bar-template' as TemplateIDCitron)
    }

    /**
     * convert vanilla TAC bar to impulse floating bar
     */
    #convertToFloatingBar() {

        /**
         * @type {HTMLElement}, outer element
         * @private
         */
        const _tacBar = document.getElementById('tarteaucitronAlertBig')

        /**
         * @type {HTMLElement}
         * @private
         */
        const _tacBtnCustomize = document.getElementById('tarteaucitronCloseAlert') // #tarteaucitronCloseAlert => personnaliser

        /**
         * @type {HTMLElement}
         * @private
         */
        const _tacBtnDenyAll = document.getElementById('tarteaucitronAllDenied2') // #tarteaucitronAllDenied2 => tout refuser

        /**
         * @type {HTMLElement}
         * @private
         */
        const _tacBtnAcceptContinue = document.getElementById('tarteaucitronPersonalize2') // #tarteaucitronPersonalize2 => accepter et continuer

        /**
         * udpate left content paragraph text
         */
        this.#floatingElement.querySelector('.js-floating-bar-text').innerText = TutoCitron.localize(this.#floatingElement.querySelector('.js-floating-bar-text').innerText)

        /**
         * update btn accept all
         */
        this.#btnAcceptAll.id = _tacBtnAcceptContinue.id
        if (_tacBtnAcceptContinue.classList.length > 0) {
            for (const _class of _tacBtnAcceptContinue.classList.entries()) {
                this.#btnAcceptAll.classList.add(_class[1])
            }
        }
        this.#btnAcceptAll.innerText = TutoCitron.localize(this.#btnAcceptAll.innerText)

        /**
         * update btn deny all
         */
        this.#btnDenyAll.id = _tacBtnDenyAll.id
        if (_tacBtnDenyAll.classList.length > 0) {
            for (const _class of _tacBtnDenyAll.classList.entries()) {
                this.#btnDenyAll.classList.add(_class[1])
            }
        }
        this.#btnDenyAll.innerText = TutoCitron.localize(this.#btnDenyAll.innerText)

        /**
         * update btn customize
         */
        this.#btnCustomize.id = _tacBtnCustomize.id
        this.#btnCustomize.innerText = TutoCitron.localize(this.#btnCustomize.innerText)

        /**
         * required data attribute to enable tuto modal
         */
        this.#btnCustomize.dataset.modalId = 'modal-tutocitron'

        this.#floatingElement.style.display = (_tacBar.style.display === 'block' && _tacBar.style.opacity === '1') ? 'block' : 'none'

        /**
         * replace vanilla bar with up-to-date floating bar
         */
        _tacBar.replaceWith(this.#floatingElement)
    }

    /**
     * init click event on buttons
     */
    #initEvents() {
        this.#btnAcceptAll.addEventListener('click', this.#acceptAllCallback)
        this.#btnDenyAll.addEventListener('click', this.#denyAllCallback)
        this.#btnCustomize.addEventListener('click', this.#customizeAllCallback)
    }

    /**
     * init event inside modal,
     * should be called after a light delay (100ms) to ensure all modal content is rendered & ready
     */
    #initModalEvents() {

        this.#tutoModal.querySelector('#' + this.#switchAllId)?.addEventListener('input', this.#switchAllCallback)

        this.#tutoModal.addEventListener('close', (event) => {
            if (event.target.returnValue === 'confirm') {

                // remove floating bar as it's not useful anymore
                this.#floatingElement.remove()

                // parse all services checkboxes
                for (const _service of Object.keys(tarteaucitron.added)) {
                    let _cb = this.#tutoModal.querySelector('input#switch-' + _service + '__service')

                    if (_cb.checked) {
                        tarteaucitron.userInterface.respond(document.getElementById(_service + 'Allowed'), true)
                    } else {
                        tarteaucitron.userInterface.respond(document.getElementById(_service + 'Denied'), false)
                    }
                }

                // push confirmation
                pushConfirmation(false)
            }

            tarteaucitron.userInterface.closePanel()
        })

        this.#modalEventInit = true
    }

    /**
     * accept all
     */
    acceptAll() {
        tarteaucitron.userInterface.respondAll(true, '', true)
        this.#hideFloatingBar()
    }

    /**
     * deny all
     */
    denyAll() {
        tarteaucitron.userInterface.respondAll(false, '', true)
        this.#hideFloatingBar()
    }

    /**
     * Hijack tarteaucitron body class to animate the floating bar translation,
     * remove floating bar element, then remove body class
     */
    #hideFloatingBar() {
        document.querySelector('body').classList.add('tarteaucitron-modal-open')
        // remove floating bar as it's not useful anymore
        setTimeout(() => {
            this.#floatingElement.remove()
            document.querySelector('body').classList.remove('tarteaucitron-modal-open')
        }, 500)
    }

    /**
     * customize (show modal)
     * triggered by customize button in floating bar
     */
    #modalEventInit = false

    customize() {
        this.#modalInstance.open()
        if (!this.#modalEventInit) {
            setTimeout(() => this.#initModalEvents(), 100)
        }
    }

    /**
     * switch all services to match global checked status
     * @param event
     */
    switchAll(event) {
        for (const [i, _cb] of this.#tutoModal.querySelectorAll('input[role="switch"]').entries()) {
            if (null !== _cb.id.match(/__service/)) {
                _cb.checked = event.currentTarget.checked
            }
        }
    }

    /**
     * Localization
     */
    static localize(key: string): string {
        if (key.includes('lang:')) {
            key = key.replace('lang:', '')
        }

        return tarteaucitron.lang[key]
    }

    /**
     * TAC OVERLOADS
     */

    /**
     * Overload of TAC vanilla function
     * @param type
     */
    jsSizing(type) {
        // void
    }
}

// ---------- Modal ----------
class TutoCitronModal {
    /**
     * @type {?HTMLDialogElement}
     */
    #dialogElement
    #closeButton
    #cancelButtons: HTMLButtonElement[] | [] = []
    #confirmButtons: HTMLButtonElement[] | [] = []
    #autofocusedButton
    #closeCallback
    #lightDismissCallback
    #handleEscapeKeyCallback
    #removeListenersCallback
    #resizeObserver
    #document
    static #translateY0 = 'translateY(0)'
    static #translateUp = 'translateY(-3.125rem)'
    static #opacity100 = '1'
    static #opacity0 = '0'

    /**
     * @param {HTMLDialogElement?} dialogElement
     */
    constructor(dialogElement) {
        this.#dialogElement = dialogElement
        this.#document = document.documentElement
        this.init()
    }

    init() {
        this.#closeCallback = this.close.bind(this, 'closed')
        this.#lightDismissCallback = TutoCitronModal.#lightDismiss.bind(this)
        this.#handleEscapeKeyCallback = this.#handleEscapeKey.bind(this)
        this.#removeListenersCallback = this.#removeListeners.bind(this)
        this.#getButtons()
    }

    open() {
        this.#addListeners()
        tarteaucitron.userInterface.openPanel()
        this.#dialogElement.showModal()
        document.activeElement?.setAttribute('autofocus', '')
        this.#translateModal('enter')
    }

    /**
     * @param {string?} message - Close the modal and dispatch message
     */
    close(message = '') {
        tarteaucitron.userInterface.closePanel()
        this.#translateModal('exit')
        setTimeout(() => {
            this.#dialogElement.close(message)
        }, 300)
    }

    #getButtons() {
        this.#closeButton = this.#dialogElement.querySelector('.js-modal-close')
        this.#cancelButtons = [...this.#dialogElement.querySelectorAll('[type="reset"]')]
        this.#confirmButtons = [...this.#dialogElement.querySelectorAll('[type="submit"]')]
        ;[...this.#cancelButtons, ...this.#confirmButtons].forEach((button) => {
            if (button.hasAttribute('autofocus')) {
                this.#autofocusedButton = button
            }
            button.innerHTML = TutoCitron.localize(button.innerHTML)
        })
    }

    #addListeners() {
        this.#dialogElement.addEventListener('keydown', this.#handleEscapeKeyCallback)
        this.#dialogElement.addEventListener('click', this.#lightDismissCallback)
        ;[this.#closeButton, ...this.#cancelButtons, ...this.#confirmButtons].forEach((button) => {
            button?.addEventListener('blur', TutoCitronModal.#removeAutofocus)
        })
        this.#closeButton?.addEventListener('click', this.#closeCallback)
        this.#cancelButtons.forEach((cancelButton) => {
            cancelButton.addEventListener('click', this.#closeCallback)
        })
        this.#dialogElement.addEventListener('close', this.#removeListenersCallback)

        this.#resizeObserver = new ResizeObserver(this.#watchResize.bind(this))
        this.#resizeObserver.observe(this.#document)
    }

    /**
     * Change modal buttons tab order on screens < 640px
     * @param {ResizeObserverEntry[]} entries
     */
    #watchResize(entries) {
        entries.forEach((entry) => {
            const primaryButton = this.#dialogElement.querySelector('.btn-primary')
            const secondaryButton = this.#dialogElement.querySelector('.btn-secondary')
            if (entry.target.clientWidth < 640) {
                primaryButton?.setAttribute('tabindex', '2')
                secondaryButton?.setAttribute('tabindex', '3')
                this.#closeButton?.setAttribute('tabindex', '1')
            } else {
                primaryButton?.removeAttribute('tabindex')
                secondaryButton?.removeAttribute('tabindex')
                this.#closeButton?.removeAttribute('tabindex')
            }
        })
    }

    /**
     * Remove listeners when closing the dialog
     * Avoid keeping event listeners in memory
     */
    #removeListeners() {
        [this.#closeButton, ...this.#cancelButtons, ...this.#confirmButtons].forEach((button) => {
            button?.removeEventListener('blur', TutoCitronModal.#removeAutofocus)
        })
        this.#closeButton?.removeEventListener('click', this.#closeCallback)
        this.#cancelButtons.forEach((cancelButton) => {
            cancelButton.removeEventListener('click', this.#closeCallback)
        })
        this.#dialogElement.removeEventListener('click', this.#lightDismissCallback)
        this.#dialogElement.removeEventListener('keydown', TutoCitronModal.#preventEscapeKey)
        this.#dialogElement.removeEventListener('keydown', this.#handleEscapeKeyCallback)
        this.#dialogElement.removeEventListener('close', this.#removeListenersCallback)

        this.#resizeObserver.disconnect()

        this.#autofocusedButton?.setAttribute('autofocus', '')
    }

    /**
     * @param {'enter' | 'exit'} direction
     */
    #translateModal(direction) {
        if ('enter' === direction) {
            this.#dialogElement.style.transform = TutoCitronModal.#translateY0
            this.#dialogElement.style.opacity = TutoCitronModal.#opacity100
        }

        if ('exit' === direction) {
            this.#dialogElement.style.transform = TutoCitronModal.#translateUp
            this.#dialogElement.style.opacity = TutoCitronModal.#opacity0
        }
    }

    /**
     * @param {HTMLDialogElement|string} htmlContent
     */
    setHTML(htmlContent) {
        this.#dialogElement.innerHTML = htmlContent instanceof HTMLElement ? htmlContent.innerHTML : htmlContent
        this.init()
    }

    /**
     * @param {HTMLElement} element
     */
    static #lightDismiss({ target: element }) {
        if ('DIALOG' === element.nodeName) {
            this.close('closed')
        }
    }

    /**
     * @param {HTMLElement} button
     */
    static #removeAutofocus({ target: button }) {
        button.removeAttribute('autofocus')
    }

    /**
     * @param {KeyboardEvent} event
     */
    static #preventEscapeKey(event) {
        if ('Escape' === event.key) {
            event.preventDefault()
        }
    }

    /**
     * @param {KeyboardEvent} event
     */
    #handleEscapeKey(event) {
        if ('Escape' === event.key) {
            event.preventDefault()
            this.close('closed')
        }
    }
}

// ---------

const showCookiesManagementPanel = () => {
    TutoCitronInst.customize()
}
window.showCookiesManagementPanel = showCookiesManagementPanel

// GTM confirmation

let GTMConfirmation = function (cookie) {
    if (cookie != null) {
        dataLayer.push({
            'event': 'tutocitronServicesUpdated',
            'tutocitronActiveServices': cookie
        })
    } else {
        dataLayer.push({ 'event': 'tutocitronServicesUpdated' })
    }
    return true
}
window.GTMConfirmation = GTMConfirmation

// ---------

// Async Push of choices

let pushConfirmation = function (_reloadThePage) {
    GTMConfirmation(tarteaucitron.cookie.read(tarteaucitron.parameters.cookieName))

    let xhttp = new XMLHttpRequest()
    xhttp.open('POST', ajax_prefixCitron + 'consent/save', true)
    xhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
    xhttp.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

    xhttp.onreadystatechange = function () {
        if (xhttp.readyState === 4 && xhttp.status === 200) {
            if (_reloadThePage === true) {
                window.location.reload()
            }
        }
    }

    xhttp.send('consent_choice=' + tarteaucitron.cookie.read(tarteaucitron.parameters.cookieName))

    if (typeof dataLayer !== 'undefined' && typeof mktcv !== 'undefined') {
        mktcv(dataLayer[0])
    }
}

// ---------
